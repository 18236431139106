import React from "react";
import ReactHtmlParser from 'react-html-parser';
import SevenDaysBanner from "../components/SevenDaysBanner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import FaceToFace from "../components/FaceToFace";
import OurFavorits from "../components/OurFavorits";
import PriceCalculationButton from "../components/PriceCalculator/PriceCalculationButton";
import { graphql } from "gatsby";

export default ({ data, pageContext }) => {
    const post = data.markdownRemark;
    const contextData = {
        title: pageContext.title,
        image: '/img/hero/' + post.frontmatter.image,
        image768: '/img/hero/' + post.frontmatter.image768,
        image1024: '/img/hero/' + post.frontmatter.image1024,
        image1216: '/img/hero/' + post.frontmatter.image1216,
        image1408: '/img/hero/' + post.frontmatter.image1408,
        defaultCssClass: 'static-hero ' + pageContext.orgSlug + '-hero',
        showPriceCalculator: false,
        showHeroMobile: true,
        noIndex: post.frontmatter.noIndex
    };
    const h2Tags = [];
    let title = pageContext.title;
    let image = '/img/hero/' + post.frontmatter.image;
    let image768 ='/img/hero/' + post.frontmatter.image768;
    let image1024 = '/img/hero/' + post.frontmatter.image1024;
    let image1216 = '/img/hero/' + post.frontmatter.image1216;
    let image1408 = '/img/hero/' + post.frontmatter.image1408;
    const elements = {
        'facetoface': FaceToFace,
        'sevendaybanner': SevenDaysBanner,
        'ourfavorits': OurFavorits,
        'pricebutton': PriceCalculationButton
    };
  return (
    <div>
        <div>
            <Header page={pageContext} siteMetadata={data.site.siteMetadata} contextData={contextData}/>
            <div className="content-hero">
                <div className="image-box">
                    <picture>
                        <source media="(max-width:768px)" srcSet={image768} />
                        <source media="(max-width:1024px)" srcSet={image1024} />
                        <source media="(max-width:1216px)" srcSet={image1216} />
                        <source media="(min-width:1217px)" srcSet={image1408} />
                        <img src={image} alt={title} title={title} />
                    </picture>
                </div>
            </div>
            <section className="static-site">
                <div className="container layout">
                    <div className="content columns">
                        <div className="column">
                            <div >{ReactHtmlParser(post.html, {
                                transform(node, index) {
                                    if (
                                        node.type === 'tag' &&
                                        node.name === 'h2' &&
                                        (typeof node.attribs !== 'undefined') &&
                                        (typeof node.attribs.id !== 'undefined')
                                        ) {
                                            h2Tags.push({ key: node.attribs.id, value: node.children[0].data });
                                    }
                                    if (node.type === 'tag') {
                                        if (elements.hasOwnProperty(node.name)) {
                                            let paramenter = node.attribs;
                                            paramenter.context = contextData;
                                            return React.createElement(elements[node.name], paramenter);
                                        }
                                    }
                                }
                            })}</div>
                        </div>
                        <div className="column is-narrow-desktop is-hidden-touch">
                            <aside className="nav-content fixed" style={{width: '250px'}}>
                                <p className="title">INHALTSVERZEICHNIS</p>
                                <ul>
                                {h2Tags.map((value) => {
                                    return (
                                        <li key={value.key}><a href={'#' + value.key}>{value.value}</a></li>
                                    );
                                })}
                                </ul>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <SevenDaysBanner cssclassName='with-background'/>
            <Footer />
        </div>
    </div>
  );
};

export const query = graphql`
  query StaticSiteQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        frontmatter {
            title
            keywords
            description
            headerImage
            ogTitle
            ogDescription
            image
            image768
            image1024
            image1216
            image1408
        }
      }
    site {
        siteMetadata {
            title
            keywords
            description
            headerImage
            ogTitle
            ogDescription
        }
    }
  }
`;
