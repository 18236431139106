import React from 'react';
import PriceCalculationButton from './PriceCalculator/PriceCalculationButton';

export default class OurFavorits extends React.Component {
    render() {
        const slug = typeof this.props.city !== 'undefined' ? this.props.city.slug : this.props.city;
        return (
            <div className="section our-favorits">
                <div className="has-text-centered"><h2 className="title">Die Favoriten von MeinAlarm24</h2></div>
                <div className="columns is-multiline">
                    <div className="column left-column">
                        <div className="title">
                            <div className="top"><img src="/img/daitem-d18-bedienelement.png" alt="Daitem D18" title="Daitem D18"/></div>
                            <div className="middle"><img src="/img/daitem-logo.png" alt="Daitem D18" title="Daitem D18"/></div>
                            <div className="bottom">
                                <PriceCalculationButton text='Was kostet eine Daitem Anlage?' city={slug} name='product' value='Daitem'/>
                            </div>
                        </div>
                    </div>
                    <div className="column right-column">
                        <div className="title">
                            <div className="top"><img src="/img/jablotron-ja-100-bedienelement.png" alt="Jablotron 100" title="Jablotron 100"/></div>
                            <div className="middle"><img src="/img/jablotron-logo.png" alt="Jablotron 100" title="Jablotron 100"/></div>
                            <div className="bottom">
                                <PriceCalculationButton text='Was kostet eine Jablotron Anlage?' city={slug} name='product' value='Jablotron'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
